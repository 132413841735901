.container {
  scroll-snap-align: start;
  height: 100%;
}
  
.event {
  position: relative;
  text-align: center;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.6;
  transform-origin: 50% 50%;
  transition: transform 300ms ease-out;
  z-index: -2;
  transform-style: flat;
}

.type {
  font-size: 1.1em;
  position: relative;
}

.titleContainer {
  &:hover ~ .image {
    transform: translate3d(-50%, -50%, -200px) rotate3d(0, 0, 0, 0deg) !important;
  }
}

.title {
  position: relative;
  font-weight: 600;
  font-size: 1.7em;
  width: 80%;
  margin: auto;
  top: 0;
  z-index: 10;
  transform: translateZ(5px);
  opacity: 1;
  transform-style: preserve-3d
}

.date {
  position: relative;
  font-size: 1.1em;
  margin-top: 15px;
}

.icon {
  position: relative;
  vertical-align: middle;
}

.more {
  position: relative;
  margin-top: 25px;
  text-decoration: underline;
}

.place {
  font-size: 1.3em;
}