html {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'Jost', sans-serif;
  background: #000;
  color: #FFF;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-size: 10vw;
  text-align: center;
  margin: 0 auto;
}

h2 {
  margin-left: 10px;
  font-size: 2em;
  font-family: 'VT323', monospace;
  font-weight: regular;
  letter-spacing: 5px;
  width: 10px;
  word-wrap: break-word;
  line-height: 0.8em;
  text-align: center;
  text-transform: uppercase;
}

.medium-zoom-image--opened { object-fit: contain; }
