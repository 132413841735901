.container {
    padding-top: 300px;
    max-width: 1500px;
    width: 90%;
    margin: auto;
}

.row {
    display: flex;
    align-items: center;
    margin-bottom: 250px;
}

.reverseRow {
    flex-direction: row-reverse;
}

.columnLeft {
    margin: 25px;
    width: 50%;
}

.columnRight {
    margin: 10px;
    width: 50%;
}

.big {
    width: 100%;
    margin: auto;
}

.small {
    width: 50%;
    margin: 50px auto;
}


@media(max-width: 900px) {
    .container {
        padding-top: 150px;
        width: 100%;
    }
    .row {
        margin-bottom: 100px;
    }

    .columnLeft {
        margin: 10px;
    }

    .columnRight {
        margin: 10px;
    }

    .small {
        width: 70%;
    }
}