.container {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.in {
    transition: opacity 1000ms 1000ms ease-in-out;
    opacity: 1;
}

.out {
    transition: opacity 800ms ease-in-out;
    opacity: 0;
}