.container { 
    color: black;
    padding: 100px 35px;
    font-weight: bold;
}

.backdrop {
    height: 85px;
    background: white;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
}

.innerContainer {
    max-width: 600px;
    margin: auto;
    position: relative;
}

.title {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: 15px;
}

.date {
    font-weight: 500;
}

.location {
    font-weight: 400;
    margin-bottom: 75px;
}

.description {
    font-weight: 400;
    text-align: justify;
    margin-bottom: 100px;
}

.images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 100px;
}

.image {
    width: 47%;
    margin-bottom: 6%;
    align-self: center;
}