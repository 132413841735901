.container {
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.image {
    width: 100%;
    display: block;
    margin-top: -100%;
    float: left;
    z-index: 1;
    cursor: pointer;
}

.thumbnail {
    width: 100%;
    display: block;
    transition: opacity 800ms ease;
    pointer-events: none;
    z-index: 2;
}