.container {
    scroll-snap-align: start;
    height: 100%;
}

.artwork {
  position: relative;
  text-align: center;
  width: 90%;
  margin: auto;
}

.image {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}

.image2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  transition: opacity 1000ms ease;
  cursor: pointer;
}

// .name {
//   position: relative;
//   font-weight: 600;
//   font-size: 1.7em;
//   width: 80%;
//   margin: auto;
//   top: 0;
//   z-index: 10;
// }

.title {
  font-weight: 600;
  font-size: 1.1em;
}

.size {
  font-weight: 400;
  font-size: 0.8em;
}

.material {
  font-weight: 400;
  font-size: 0.8em;
  display: none;
}

@media (max-width: 800px) {
  .material {
    display: none;
  }
}

.description {
  position: relative;
  font-size: 1.1em;
  margin-top: 15px;
}