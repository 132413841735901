.container { 
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    background: #000;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display:none;
    }
}

.wordCloud {
    height: 100%;
    width: 80%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    & > h1 {
        font-size: 6em;
        font-weight: 500;
        color: #AAA;
    }
}

@media (max-width: 800px) {
    .wordCloud {
        & > h1 {
            font-size: 3.5em;
        }
    }
}

.masonry {
    margin-top: 50px;
    position: relative;
    width: 100%;
}