.container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 5;
    overflow: scroll;
    transition: opacity 500ms ease;
}

.icon {
    position: absolute;
    right: 35px;
    top: 35px;
    color: black;
}