.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    // background: url('/images/bgstory.jpeg');
    // background-size: cover;
    // background-position: 50% 50%;
}

.paragraph {
    position: relative;
    font-size: 1.3em;
    max-width: 320px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    // background: black;
}

.analogdream {
    font-size: 2.5em;
    // transform: rotate3d(2, 0, 1, 180deg);
}

.ev {
    font-size: 1em;
}

.vivus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-57%, -50%);
    height: 100%;
    width: 100%;
    max-width: 500px;
    pointer-events: none;
    // color: white !important;
}

.icons {
    color: #FFF;
    width: 60%;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media(max-width: 600px) {
    .paragraph {
        font-size: 1.1em;
    }
}