.container { 
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: auto;
    padding-right: 17px;
    width: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display:none;
    }
}