.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 12;
    transition: opacity 600ms;
    cursor: url("/icons/close.png") 16 16, auto;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 600ms;
}

.infoButton {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.info {
    position: absolute;
    max-width: 500px;
    width: 80%;
    word-break: break-word;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 600ms;
    pointer-events: none;
}