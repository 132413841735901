.outerContainer {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    height: 100%;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display:none;
    }
}

.innerContainer {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.item {
    scroll-snap-align: center;
    user-select: none;
}

.line {
    position: absolute;
    width: 100%;
    height: 2px;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    top: 333px;
}