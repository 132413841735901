.container {
    scroll-snap-align: start;
    height: 100%;
}

.member {
  position: relative;
  text-align: center;
  color:  #fd0000;
}

.name {
  position: relative;
  font-weight: 600;
  font-size: 1.5em;
  width: 80%;
  margin: auto;
  top: 0;
}

.description {
  position: relative;
  font-size: 1em;
  font-weight: 400;
}

.image {
  position: relative;
  width: 80%;
  max-width: 350px;
  margin-bottom: 25px;
}