.container {
    height: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
    display: flex;
}

.item {
    align-items: center;
    position: relative;
    transition: opacity 500ms ease-in-out;
}

.image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    // max-width: 550px;
    transition: opacity 700ms ease-in-out;
}

.text {
    position: relative;
    transform: translate3d(0, 0, 0);
    font-size: 7vw;
    line-height: 12vw;
    white-space: pre-wrap;
    padding-left: 15px;
    border-width: 0 0 0 1px;
    font-weight: 500;
}

@media(min-width: 550px) {
    .text {
        font-size: 2em;
        line-height: 2em;
        border-width: 0 0 0 2px;
        border-style: solid;
    }

    .image {
        width: 80%;
    }
}