.container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 100%;
    pointer-events: none;
    border-color: white;
    overflow: hidden;
    mix-blend-mode: difference;
    border-style: solid inset solid solid;
    border-width: 2px;
    box-sizing: border-box;
    user-select: none;
    font-size: 0.9em;
    font-weight: 400;
}

@media (min-width: 700px) {
    .container {
        font-size: 1.2em;
    }
}

.hover{}
.scrollAway {}
.enlarge {}



.topContainer {
    position: absolute;
    z-index: 11;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: all;
    height: 120px;
    width: 60px;
    cursor: pointer;

    &:hover > .top {
        transform: translateY(-100px) !important;
    }

    &:hover > .top > .topText {
        transform: translateX(-50%) scale(2) !important;
    }
}

.top {
    position: absolute;
    top: 0;
    left: 50%;
    transition: transform 300ms, opacity 800ms;
    transform: translateY(-180px);

    &.enlarge {
        transform: translateY(-100px);
    }

    &.scrollAway {
        transform: translateY(-300px);
    }
}

.topLine {
    position: absolute;
    top: 0px;
    height: 200px;
    width: 1px;
    background: white;
}

.topText {
    position: absolute;
    top: 200px;
    text-transform: lowercase;
    transform-origin: 50% 0;
    transition: transform 300ms;
    transform: translateX(-50%) scale(1);

    &.enlarge {
        transform: translateX(-50%) scale(2);
    }
}

.bottomContainer {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 120px;
    width: 60px;
    transform: translateX(-50%);
    cursor: pointer;

    &:hover > .bottom {
        transform: translateY(-100px) !important;
    }

    &:hover > .bottom > .bottomText {
        transform: translateX(-50%) scale(2) !important;
    }
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: transform 300ms, opacity 800ms;
    transform: translateY(0);

    &.enlarge {
        transform: translateY(-100px);
    }

    &.scrollAway {
        transform: translateY(100px);
    }
}

.bottomLine {
    position: absolute;
    bottom: -180px;
    height: 200px;
    width: 1px;
    background: white;
}

.bottomText {
    position: absolute;
    bottom: 20px;
    text-transform: lowercase;
    transform-origin: 50% 100%;
    transition: transform 300ms;
    transform: translateX(-50%) scale(1);

    &.enlarge {
        transform: translateX(-50%) scale(2);
    }
}

.linkToImages {
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 300ms, opacity 800ms;
}

.linkToImagesContainer {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 120px;
    height: 80px;
    pointer-events: all;
    cursor: pointer;
    transition: opacity 800ms 1000ms;

    &:hover > .linkToImages > .linkToImagesText,
    &.hover > .linkToImages > .linkToImagesText{
        transform: scale(1.5);
    }

    &:hover > .linkToImages,
    &.hover > .linkToImages {
        transform: translate(-25px, 25px) !important;
    }
}

.linkToImagesText {
    position: relative;
    right: 27px;
    top: 20px;
    text-align: right;
    transition: transform 300ms;
    transform-origin: 100% 0;
}

.linkToImagesLine {
    position: absolute;
    top: -35px;
    right: -35px;
    transform: rotate(45deg);
    transform-origin: 0 0;
    width: 1px;
    height: 80px;
    background: white;
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms, opacity 800ms;
}

.backContainer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 120px;
    height: 80px;
    pointer-events: all;
    cursor: pointer;
    transition: opacity 800ms 1000ms;

    &:hover > .back > .backText,
    &.hover > .back > .backText{
        transform: scale(1.5);
    }

    &:hover > .back,
    &.hover > .back {
        transform: translate(25px, 25px) !important;
    }
}

.backText {
    position: relative;
    left: 27px;
    top: 20px;
    text-align: right;
    transition: transform 300ms;
    transform-origin: 0 0;
}

.backLine {
    position: absolute;
    top: -35px;
    left: -35px;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    width: 1px;
    height: 80px;
    background: white;
}