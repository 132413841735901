.image {
    width: 100%;
    transition: transform 600ms;
}

.play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    font-size: 4em;
}

@media (min-width: 600px) {
    .play {
        font-size: 7em;
    }
}